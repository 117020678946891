import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;

const AllDataPoints = [
    {
        id: 'nav',
        securities: 'FO,FE,FC',
        selected: 'FO,FE,FC',
        dataType: 'number'
    },
    {
        id: 'fundSize',
        securities: 'FO,FE,FC',
        selected: 'FO,FE,FC',
        dataType: 'int'
    },
    {
        id: 'expenses',
        securities: 'FO,FE,FC',
        dataType: 'number'
    },
    {
        id: 'minimumInitialInvestment',
        securities: 'FO',
        dataType: 'string'
    },
    {
        id: 'turnover',
        securities: 'FO,FE,FC',
        dataType: 'number'
    },
    {
        id: 'openPrice',
        securities: 'ST,FE,FC,XI,OP',
        selected: 'ST,FE,FC,XI,OP',
        dataType: 'number'
    },
    {
        id: 'highPrice',
        securities: 'ST,FE,FC,XI,OP',
        selected: 'ST,FE,FC,XI,OP',
        dataType: 'number'
    },
    {
        id: 'lowPrice',
        securities: 'ST,FE,FC,XI,OP',
        selected: 'ST,FE,FC,XI,OP',
        dataType: 'number'
    },
    {
        id: 'closePrice',
        securities: 'ST,FE,FC,XI,OP',
        selected: 'ST,FE,FC,XI,OP',
        dataType: 'number'
    },
    {
        id: 'dayRange',
        securities: 'ST,FE,FC,XI',
        inputs: [
            {
                id: 'lowPrice',
                dataType: 'number'
            },
            {
                id: 'highPrice',
                dataType: 'number'
            }
        ],
        render: (lowPrice, highPrice) => {
            return `${lowPrice}-${highPrice}`;
        }
    },
    {
        id: 'bid',
        securities: 'ST,FE,FC,OP',
        selected: 'ST,FE,FC,OP',
        inputs: [
            {
                id: 'bidPrice',
                dataType: 'number'
            },
            {
                id: 'bidSize',
                dataType: 'int'
            }
        ],
        render: (bidPrice, bidSize) => {
            return `${bidPrice}/${bidSize}`;
        }
    },
    {
        id: 'ask',
        securities: 'ST,FE,FC,OP',
        selected: 'ST,FE,FC,OP',
        inputs: [
            {
                id: 'askPrice',
                dataType: 'number'
            },
            {
                id: 'askSize',
                dataType: 'int'
            }
        ],
        render: (askPrice, askSize) => {
            return `${askPrice}/${askSize}`;
        }
    },
    {
        id: 'rtMarketCap',
        securities: 'ST,FC',
        selected: 'ST,FC',
        dataType: 'abbreviation'
    },
    {
        id: 'week52HighPrice',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'week52LowPrice',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'week52Range',
        securities: 'ST,FO,FE,FC,XI',
        inputs: [
            {
                id: 'week52LowPrice',
                dataType: 'number'
            },
            {
                id: 'week52HighPrice',
                dataType: 'number'
            }
        ],
        render: (week52LowPrice, week52HighPrice) => {
            return `${week52LowPrice}-${week52HighPrice}`;
        }
    },
    {
        id: 'fairValueEstimate',
        securities: 'ST,FE',
        selected: 'ST,FE',
        dataType: 'number'
    },
    {
        id: 'dividendYieldCurrent',
        label: 'dividendYieldCurrentLabel',
        securities: 'ST,FE',
        selected: 'ST',
        dataType: 'number'
    },
    {
        id: 'lastDividend',
        label: 'lastDividendLabel',
        securities: 'ST,FO,FE,FC',
        selected: 'ST,FO,FE,FC',
        dataType: 'number'
    },
    {
        id: 'lastDividendDate',
        label: 'lastDividendDateLabel',
        securities: 'ST,FO,FE,FC',
        dataType: 'date'
    },
    {
        id: 'MSSharesOutstanding',
        securities: 'ST,FC',
        selected: 'ST',
        dataType: 'int'
    },
    {
        id: 'threeMonthAvgVolume',
        securities: 'ST,FE,FC',
        selected: 'ST,FE,FC',
        dataType: 'abbreviation'
    },
    {
        id: 'economicMoat',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'ST,FO,FE,FC,XI',
        dataType: 'string'
    },
    {
        id: 'MSStyleBox',
        securities: 'ST,FO,FE,FC',
        selected: 'ST,FO,FE,FC',
        dataType: 'string'
    },
    {
        id: 'morningstarCategory',
        securities: 'FO,FE,FC',
        selected: 'FO,FC',
        dataType: 'string'
    },
    {
        id: 'status',
        securities: 'ST,FE,FC,XI',
        dataType: 'string'
    },
    {
        id: 'week52HighDate',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'date'
    },
    {
        id: 'week52LowDate',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'date'
    },
    {
        id: 'VWAP',
        securities: 'ST,FE,FC,OP',
        selected: 'ST,FE,FC,OP',
        dataType: 'number'
    },
    {
        id: 'beta',
        securities: 'ST',
        selected: 'ST',
        dataType: 'number'
    },
    {
        id: 'PE',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'forwardPERatio',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'PB',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'PS',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'PCF',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'EPSTTM',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'standardDeviationOneYear',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'standardDeviationThreeYear',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'PCFRatio',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'PFCFRatio',
        securities: 'ST',
        dataType: 'number'
    },
    {
        id: 'priceToFairValue',
        securities: 'ST,FE',
        dataType: 'number'
    },
    {
        id: 'IPODate',
        securities: 'ST',
        dataType: 'date'
    },
    {
        id: 'forwardDividendYield',
        securities: 'ST,FO,FE,FC',
        selected: 'FO,FE,FC',
        dataType: 'number'
    },
    {
        id: 'oneDayReturn',
        label: 'oneDayReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'oneWeekReturn',
        label: 'oneWeekReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'oneMonthReturn',
        label: 'oneMonthReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'threeMonthReturn',
        label: 'threeMonthReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'sixMonthReturn',
        label: 'sixMonthReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'ytdReturn',
        label: 'ytdReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'oneYearReturn',
        label: 'oneYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'twoYearReturn',
        label: 'twoYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'threeYearReturn',
        label: 'threeYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'fiveYearReturn',
        label: 'fiveYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        selected: 'FO,XI',
        dataType: 'number'
    },
    {
        id: 'tenYearReturn',
        label: 'tenYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'fifteenYearReturn',
        label: 'fifteenYearReturnLabel',
        securities: 'ST,FO,FE,FC,XI',
        dataType: 'number'
    },
    {
        id: 'fiscalYearEndMonth',
        label: 'fiscalYearEndMonthLabel',
        securities: 'ST,FO,FE,FC',
        dataType: 'string'
    },
    {
        id: 'tradeExchange',
        securities: 'ST,FO,FE,FC,XI,OP',
        selected: 'OP',
        dataType: 'string'
    },
    {
        id: 'strikePrice',
        securities: 'OP',
        selected: 'OP',
        dataType: 'number'
    },
    {
        id: 'openInterest',
        securities: 'OP',
        selected: 'OP'
    },
    {
        id: 'expirationDate',
        securities: 'OP',
        selected: 'OP',
        dataType: 'date'
    }
];

export function getDataModelPointIds(securityType) {
    const dataPoints = [
        'volume',
        'MSRating',
        'quantitativeValuation',
        'exchangeName',
        'lastPrice',
        'chg',
        'chg%',
        'tradeDateTime',
        'prePostPrice',
        'prePostChg',
        'prePostChg%',
        'prePostDateTime',
        'contractName',
        'extraExchangeInfo'
    ];
    AllDataPoints.forEach(d => {
        if (d.securities.indexOf(securityType) > -1) {
            if (!d.inputs) {
                dataPoints.push(d.id);
            } else {
                dataPoints.push(...d.inputs.map(input => input.id));
            }
        }
    });
    return dataPoints;
}

export function getAvailableDataPoints(securityType = 'ST') {
    const dataPoints = [];
    AllDataPoints.forEach(d => {
        if (d.securities.indexOf(securityType) > -1) {
            dataPoints.push(d);
        }
    });
    return dataPoints;
}

export function getSelectedDataPoints(securityType = 'ST', dataPointIds = []) {
    let selected = dataPointIds.slice();
    if (selected.length === 0) {
        AllDataPoints.forEach(d => {
            if (d.selected && d.selected.indexOf(securityType) > -1) {
                selected.push(d.id);
            }
        });
    }
    const selectedDataPoints = [];
    selected.forEach(id => {
        const find = utils.find(AllDataPoints, d => d.id === id);
        if (find) {
            selectedDataPoints.push(find);
        }
    });
    return selectedDataPoints;
}

export function getSelectedDataPointIds(
    securityType = 'ST',
    dataPointIds = []
) {
    return getSelectedDataPoints(securityType, dataPointIds).map(d => d.id);
}

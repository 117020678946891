<template>
    <markets-ui-container
        :class="namespace()"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <div slot="content" :class="namespace('content')" v-if="!errorMessage">
            <div
                v-if="watchedSettings.showContentHeader"
                :class="[
                    namespace('content-header'),
                    headerData.prePostPriceData
                        ? namespace('content-header__with-pre-post')
                        : ''
                ]"
                ref="contentHeader"
            >
                <div :class="namespace('content-header-info-container')">
                    <div
                        :class="[
                            namespace('content-header-basic'),
                            namespace(`content-header-basic--${breakPoint}`)
                        ]"
                    >
                        <div
                            :class="[
                                namespace('content-header-block'),
                                namespace('content-header__security')
                            ]"
                        >
                            <div>
                                <span
                                    :class="
                                        namespace(
                                            'content-header__security-name'
                                        )
                                    "
                                    >{{ headerData.security.name }}</span
                                >
                                <span
                                    :class="
                                        namespace(
                                            'content-header__security-symbol'
                                        )
                                    "
                                    >{{ headerData.security.symbol }}</span
                                >
                            </div>
                            <div
                                :class="
                                    namespace('content-header__security-info')
                                "
                            >
                                <span>{{ headerData.security.currency }}</span>
                                <span
                                    >| {{ headerData.security.exchange }}</span
                                >
                            </div>
                        </div>
                        <div
                            v-if="isLayoutChanged"
                            :class="[
                                namespace('content-header-block'),
                                namespace('content-header__price-quant'),
                                namespace(
                                    `content-header__price-quant-${breakPoint}`
                                )
                            ]"
                        >
                            <div
                                :class="[
                                    namespace(
                                        `content-header__price-quant-block`
                                    ),
                                    namespace('content-header__price')
                                ]"
                            >
                                <div>
                                    <span ref="lastPrice">{{
                                        headerData.regularPice.price
                                    }}</span>
                                    <markets-ui-icon
                                        v-if="
                                            headerData.regularPice.updown !==
                                                'neutral'
                                        "
                                        :skin="skin"
                                        :performance="
                                            headerData.regularPice.updown
                                        "
                                        :name="
                                            `ip-performance-arrow-${headerData.regularPice.updown}`
                                        "
                                    />
                                    <span
                                        :class="
                                            namespace(
                                                headerData.regularPice.updown
                                            )
                                        "
                                    >
                                        <label ref="chg">{{
                                            headerData.regularPice.chg
                                        }}</label>
                                        |
                                        <label ref="chg%">
                                            {{ headerData.regularPice.chgp }}
                                            <sup>%</sup>
                                        </label>
                                    </span>
                                </div>
                                <div>
                                    <span v-if="headerData.regularPice.volume">
                                        {{ initedLabels.volumeAbbriation }}
                                        {{ headerData.regularPice.volume }}
                                    </span>
                                    <span
                                        :class="
                                            namespace(
                                                `content-header__price-date`
                                            )
                                        "
                                    >
                                        {{ headerData.regularPice.dateTime }}
                                    </span>
                                    <span
                                        :class="
                                            namespace(
                                                `content-header__price-date`
                                            )
                                        "
                                        @mouseenter="showTooltip"
                                        @mouseleave="hideTooltip"
                                    >
                                        {{
                                            headerData.regularPice.shorTimeZone
                                        }}
                                    </span>
                                    <markets-ui-tooltip
                                        v-if="
                                            watchedSettings.showTimeZoneToolTip &&
                                                dataModel.security.timezone &&
                                                dataModel.security
                                                    .timezoneAbbreviation
                                        "
                                        :visible="tooltips.visible"
                                        :triggered-by="tooltips.triggeredBy"
                                        :class="namespace('tooltips')"
                                        :text="
                                            headerData.regularPice.timezoneAbb
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                :class="[
                                    namespace(
                                        `content-header__price-quant-block`
                                    ),
                                    namespace('content-header__quant')
                                ]"
                            >
                                <div v-if="headerData.quant.rating">
                                    <markets-ui-star-rating
                                        :skin="watchedSettings.skin"
                                        :value="headerData.quant.rating"
                                    ></markets-ui-star-rating>
                                </div>
                                <div v-if="headerData.quant.valuation">
                                    {{ headerData.quant.valuation }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!isLayoutChanged"
                            :class="[
                                namespace('content-header-block'),
                                namespace('content-header__price')
                            ]"
                        >
                            <div>
                                <span ref="lastPrice">
                                    {{ headerData.regularPice.price }}
                                </span>
                                <markets-ui-icon
                                    v-if="
                                        headerData.regularPice.updown !==
                                            'neutral'
                                    "
                                    :performance="headerData.regularPice.updown"
                                    :skin="skin"
                                    :name="
                                        `ip-performance-arrow-${headerData.regularPice.updown}`
                                    "
                                />
                                <span
                                    :class="
                                        namespace(headerData.regularPice.updown)
                                    "
                                >
                                    <label ref="chg">{{
                                        headerData.regularPice.chg
                                    }}</label>
                                    |
                                    <label ref="chg%">
                                        {{ headerData.regularPice.chgp }}
                                        <sup>%</sup>
                                    </label>
                                </span>
                            </div>
                            <div>
                                <span v-if="headerData.regularPice.volume">
                                    {{ initedLabels.volumeAbbriation }}
                                    {{ headerData.regularPice.volume }}
                                </span>
                                <span
                                    :class="
                                        namespace(`content-header__price-date`)
                                    "
                                    >{{ headerData.regularPice.dateTime }}</span
                                >
                                <span
                                    :class="
                                        namespace(`content-header__price-date`)
                                    "
                                    @mouseenter="showTooltip"
                                    @mouseleave="hideTooltip"
                                >
                                    {{ headerData.regularPice.shorTimeZone }}
                                </span>
                                <markets-ui-tooltip
                                    v-if="
                                        watchedSettings.showTimeZoneToolTip &&
                                            dataModel.security.timezone &&
                                            dataModel.security
                                                .timezoneAbbreviation
                                    "
                                    :visible="tooltips.visible"
                                    :triggered-by="tooltips.triggeredBy"
                                    :class="namespace('tooltips')"
                                    :text="headerData.regularPice.timezoneAbb"
                                />
                            </div>
                        </div>
                        <div
                            v-if="!isLayoutChanged"
                            :class="[
                                namespace('content-header__block'),
                                namespace('content-header__quant')
                            ]"
                        >
                            <div v-if="headerData.quant.rating">
                                <markets-ui-star-rating
                                    :skin="watchedSettings.skin"
                                    :value="headerData.quant.rating"
                                >
                                </markets-ui-star-rating>
                            </div>
                            <div v-if="headerData.quant.valuation">
                                {{ headerData.quant.valuation }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="headerData.prePostPriceData && showPrePost"
                        :class="namespace('content-header-plus')"
                    >
                        <div :class="namespace('content-header__price')">
                            <div>
                                <label
                                    :class="
                                        namespace(
                                            'content-header__price-pre-post-label'
                                        )
                                    "
                                >
                                    {{ headerData.prePostPriceData.label }}
                                </label>
                                <span ref="prePostPrice">
                                    {{ headerData.prePostPriceData.price }}
                                    |
                                </span>
                                <span
                                    :class="
                                        namespace(
                                            headerData.prePostPriceData.updown
                                        )
                                    "
                                >
                                    <label ref="prePostChg%">
                                        {{ headerData.prePostPriceData.chgp }}
                                        <sup>%</sup>
                                    </label>
                                </span>
                            </div>
                            <div
                                :class="namespace(`content-header__price-date`)"
                            >
                                {{ headerData.prePostPriceData.dateTime }}
                            </div>
                            <div
                                :class="namespace(`content-header__price-date`)"
                                @mouseenter="showTooltip"
                                @mouseleave="hideTooltip"
                            >
                                {{ headerData.regularPice.shorTimeZone }}
                            </div>
                            <markets-ui-tooltip
                                v-if="
                                    watchedSettings.showTimeZoneToolTip &&
                                        dataModel.security.timezone &&
                                        dataModel.security.timezoneAbbreviation
                                "
                                :visible="tooltips.visible"
                                :triggered-by="tooltips.triggeredBy"
                                :class="namespace('tooltips')"
                                :text="headerData.regularPice.timezoneAbb"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="showWebLayout"
                    :class="namespace('content-header__settings')"
                >
                    <markets-ui-button
                        ref="settingsButton"
                        :skin="skin"
                        icon="gear"
                        size="medium"
                        :text="initedLabels.settings"
                        variation="icon-only"
                        :aria-expanded="'' + settingsPopoverVisible"
                        @click="toggleSettings($event)"
                    />
                </div>
            </div>
            <div
                :class="[
                    namespace('content-body'),
                    watchedSettings.drag
                        ? namespace('content-body__datapoint--draggable')
                        : ''
                ]"
                :style="
                    watchedSettings.autoHeight
                        ? ''
                        : `height:calc(100% - ${contentHeaderHeight +
                              contentBodyPadding}px);`
                "
            >
                <draggable
                    :draggable="
                        `.${namespace(
                            'content-body__datapoint--draggable-item'
                        )}`
                    "
                    :list="dragDataPoints"
                    :sort="watchedSettings.drag"
                    :class="[
                        namespace('content-body__datapoint-wrapper'),
                        namespace(
                            `content-body__datapoint-wrapper--${breakPoint}`
                        )
                    ]"
                    @change="orderDataPoints"
                >
                    <span
                        v-for="item in dragDataPoints"
                        :class="[
                            namespace('content-body__datapoint'),
                            watchedSettings.drag
                                ? namespace(
                                      'content-body__datapoint--draggable-item'
                                  )
                                : ''
                        ]"
                        :key="item.id"
                    >
                        <dl>
                            <dt>{{ item.name }}</dt>
                            <dd v-if="item.id === 'MSStyleBox'">
                                <markets-ui-style-box
                                    :skin="watchedSettings.skin"
                                    :value="item.value"
                                >
                                </markets-ui-style-box>
                            </dd>
                            <dd x-ms-format-detection="none" v-else>
                                {{ item.value }}
                            </dd>
                        </dl>
                        <markets-ui-button
                            v-if="watchedSettings.removable"
                            aria-hidden="true"
                            tabindex="-1"
                            :skin="skin"
                            variation="icon-only"
                            size="small"
                            icon="remove"
                            :class="
                                namespace('content-body__datapoint--remove')
                            "
                            :text="initedLabels.remove"
                            @click="removeDataPoint(item)"
                        ></markets-ui-button>
                    </span>
                </draggable>
            </div>
        </div>
        <markets-ui-settings
            ref="quoteUiSettings"
            slot="settings"
            :class="namespace('settings')"
            :skin="watchedSettings.skin"
            :showBack="!!showDataPointsSetting && !showWebLayout"
            :showDone="!!showDataPointsSetting"
            :showSearchField="showDataPointsSetting"
            :triggered-by="settingsPopoverTriggerId"
            :title="settingsPopoverTitle"
            :doneLabel="initedLabels.labelDone"
            :visible="settingsPopoverVisible"
            :searchFieldPlaceholder="initedLabels.search"
            @hide="hideSettingsPopover"
            @back="changeSettingsNav"
            @done="changeDataPoints"
            @search-key-changed="filterDataPoints"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected && !showWebLayout"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            >
            </markets-ui-settings-navigation>
            <markets-ui-form slot="content">
                <markets-ui-checkbox-group
                    v-if="showDataPointsSetting"
                    size="small"
                    :skin="watchedSettings.skin"
                    :dataModel="settingDataPoints"
                    @change="changeSettingDataPoints"
                ></markets-ui-checkbox-group>
            </markets-ui-form>
        </markets-ui-settings>
    </markets-ui-container>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import {
    getAvailableDataPoints,
    getSelectedDataPoints,
    getSelectedDataPointIds
} from './metadata/data-point';
import draggable from 'vuedraggable';
import { SECURITY_TYPES, getSecurityType } from './metadata/sec-types';
const { utils, mixins } = mwcMarketsCore;
export default {
    mixins: [mixins.componentUI],
    name: 'mwc-markets-quote-ui',
    components: { draggable },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {
                    security: {},
                    data: {}
                };
            }
        },
        showPrePost: {
            type: Boolean
        }
    },
    data() {
        return {
            breakPoint: 'xl',
            contentHeaderHeight: 0,
            contentBodyPadding: 20,
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            settingDataPoints: null,
            searchKey: '',
            tooltips: {
                visible: true,
                text: '',
                position: ['right-center'],
                triggeredBy: null
            }
        };
    },
    computed: {
        dragDataPoints() {
            return this._getDataPoints();
        },
        settingsPopoverNav() {
            if (this.settingDataPoints) {
                return [
                    {
                        id: 'dataPoints',
                        title: this.initedLabels['dataPoints'],
                        name: `${
                            this.settingDataPoints.filter(d => d.selected)
                                .length
                        } ${this.initedLabels['selected']}`
                    }
                ];
            } else {
                return [];
            }
        },
        settingsPopoverTitle() {
            if (this.settingsPopoverNavSelected) {
                return this.settingsPopoverNavSelected.title;
            } else if (this.showDataPointsSetting) {
                return this.initedLabels['dataPoints'];
            }
            return this.initedLabels && this.initedLabels.settingTitle;
        },
        headerData() {
            const volume = utils.getFormatValue({
                formatter: this.formatter,
                value: this.dataModel.data.volume,
                dataType: 'abbreviation'
            });
            const rating = utils.getFormatValue({
                formatter: this.formatter,
                value: this.dataModel.data.MSRating,
                dataType: 'string'
            });
            const valuation = utils.getFormatValue({
                formatter: this.formatter,
                value: this.dataModel.data.quantitativeValuation,
                dataType: 'string'
            });
            const data = {
                security: {
                    name:
                        this.dataModel.security.name ||
                        this.dataModel.data.contractName ||
                        this.formatter.format(),
                    symbol: this.getDisplaySymbol(this.dataModel.security),
                    currency: utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.security.currency,
                        dataType: 'string'
                    }),
                    exchange: utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.data.exchangeName,
                        dataType: 'string'
                    })
                },
                regularPice: {
                    price: utils.getFormatValue({
                        formatter: this.formatter,
                        dataType: 'number',
                        value: this.dataModel.data.lastPrice
                    }),
                    chg: utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.data.chg,
                        dataType: 'number'
                    }),
                    chgp: utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.data['chg%'],
                        dataType: 'number'
                    }),
                    updown: this._getUpDown(this.dataModel.data.chg),
                    volume: this.dataModel.data.volume > 0 ? volume : '',
                    dateTime: `${utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.data.tradeDateTime,
                        dataType: 'dateTime'
                    })}`,
                    shorTimeZone: `${utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.security.timezoneAbbreviation,
                        dataType: 'string'
                    })}`,
                    timezoneAbb: this.getTimeZoneAbb()
                },
                quant: {
                    rating: this._inRange(this.dataModel.data.MSRating, [1, 5])
                        ? rating
                        : '',
                    valuation: this.dataModel.data.quantitativeValuation
                        ? valuation
                        : ''
                }
            };

            if (
                this.watchedSettings.showPrePost &&
                (this.dataModel.data.status === 'Pre-Open' ||
                    this.dataModel.data.status === 'Post-Trading')
            ) {
                data.prePostPriceData = {
                    label: this.initedLabels[
                        this.dataModel.data.status === 'Pre-Open'
                            ? 'preMarket'
                            : 'afterHours'
                    ],
                    price: utils.getFormatValue({
                        formatter: this.formatter,
                        dataType: 'number',
                        value: this.dataModel.data.prePostPrice
                    }),
                    chg: utils.getFormatValue({
                        formatter: this.formatter,
                        dataType: 'number',
                        value: this.dataModel.data.prePostChg
                    }),
                    chgp: utils.getFormatValue({
                        formatter: this.formatter,
                        dataType: 'number',
                        value: this.dataModel.data['prePostChg%']
                    }),
                    updown: this._getUpDown(this.dataModel.data.prePostChg),
                    dateTime: `${utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.data.prePostDateTime,
                        dataType: 'dateTime'
                    })}`,
                    shorTimeZone: `${utils.getFormatValue({
                        formatter: this.formatter,
                        value: this.dataModel.security.timezoneAbbreviation,
                        dataType: 'string'
                    })}`,
                    timezoneAbb: this.getTimeZoneAbb()
                };
            }
            return data;
        },
        isLayoutChanged() {
            return !/.*l/.test(this.breakPoint);
        },
        showDataPointsSetting() {
            return (
                (this.settingsPopoverNavSelected &&
                    this.settingsPopoverNavSelected.id === 'dataPoints') ||
                (this.settingsPopoverVisible && this.showWebLayout)
            );
        }
    },
    watch: {
        'dataModel.data': {
            handler(value, oldValue) {
                this.flashDataPoints(value, oldValue);
            },
            deep: true
        },
        'dataModel.security.queryKey': function(value, oldValue) {
            if (value) {
                this.changeSettings(this._getStoreSettings());
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('quote');
        Object.keys(SECURITY_TYPES).forEach(securityType => {
            if (this.watchedSettings[`${securityType}DataPoints`]) {
                this.watchedSettings[
                    `${securityType}DataPoints`
                ] = this.watchedSettings[`${securityType}DataPoints`].split(
                    ','
                );
            } else {
                this.watchedSettings[`${securityType}DataPoints`] = [];
            }
        });
        this.settingDataPointsSelected = null;
        this.watchedSettings.drag =
            this.watchedSettings.drag !== false ? true : false;
        this.trackDataPointsUsage();
    },
    methods: {
        getTimeZoneAbb() {
            return new Date()
                .toLocaleDateString(this.watchedSettings.languageId, {
                    day: '2-digit',
                    timeZoneName: 'long',
                    timeZone: this.dataModel.security.timezone
                })
                .slice(4);
        },
        _resizeObserverCallback({ entry }) {
            this.hideSettingsPopover();
            this.breakPoint = this._calculateBreakPoint(
                entry.contentRect.width
            );
            this.$nextTick(() => {
                if (this.watchedSettings.showContentHeader) {
                    this.contentHeaderHeight = utils.outerSizes(
                        this.$refs.contentHeader,
                        true
                    ).height;
                }
            });
        },
        _getUpDown(value) {
            if (value > 0) {
                return 'up';
            } else if (value < 0) {
                return 'down';
            } else {
                return 'neutral';
            }
        },
        _getSettingDataPoints() {
            const securityType = getSecurityType(this.dataModel.security);
            const dataPoints =
                this.watchedSettings[`${securityType}DataPoints`] ||
                this.watchedSettings.STDataPoints;
            let selectedDataPointIds;
            if (this.settingDataPointsSelected) {
                selectedDataPointIds = Object.keys(
                    this.settingDataPointsSelected
                ).filter(key => this.settingDataPointsSelected[key]);
            } else {
                selectedDataPointIds = getSelectedDataPointIds(
                    securityType,
                    dataPoints
                );
            }
            return getAvailableDataPoints(securityType)
                .map(d => {
                    const name = this.initedLabels[d.label || d.id];
                    return {
                        id: d.id,
                        name,
                        selected:
                            utils.inArray(selectedDataPointIds, d.id) > -1,
                        visible: this._isVisibleSettingsDataPoint(name)
                    };
                })
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    } else if (a.name === b.name) {
                        return 0;
                    } else {
                        return 1;
                    }
                });
        },
        _getDataPoints() {
            const securityType = getSecurityType(this.dataModel.security);
            const dataPoints =
                this.watchedSettings[`${securityType}DataPoints`] ||
                this.watchedSettings['STDataPoints'];

            const selectedDataPoints = getSelectedDataPoints(
                securityType,
                dataPoints
            );
            return selectedDataPoints.map(d => {
                const name = this.initedLabels[d.label || d.id];
                let value;
                if (d.inputs) {
                    value = d.render(
                        ...d.inputs.map(sd => {
                            return utils.getFormatValue({
                                formatter: this.formatter,
                                dataType: sd.dataType,
                                value: this.dataModel.data[sd.id]
                            });
                        })
                    );
                } else {
                    value = utils.getFormatValue({
                        formatter: this.formatter,
                        dataType: d.dataType,
                        value: this.dataModel.data[d.id]
                    });
                }
                return {
                    id: d.id,
                    value,
                    name
                };
            });
        },
        _calculateBreakPoint(width) {
            let breakPoint = 'xl';
            if (this._inRange(width, [0, 480])) {
                breakPoint = 'xs';
            } else if (this._inRange(width, [481, 768])) {
                breakPoint = 's';
            } else if (this._inRange(width, [769, 800])) {
                breakPoint = 'm';
            } else if (this._inRange(width, [801, 1024])) {
                breakPoint = 'l';
            } else if (this._inRange(width, [1025, 1280])) {
                breakPoint = 'xl';
            } else if (width >= 1280) {
                breakPoint = 'xxl';
            }
            return breakPoint;
        },
        _inRange(value, range) {
            const min = Math.min(range[0], range[1]);
            const max = Math.max(range[0], range[1]);
            return value >= min && value <= max;
        },
        _isVisibleSettingsDataPoint(name) {
            return this.searchKey
                ? name.toLowerCase().indexOf(this.searchKey.toLowerCase()) > -1
                : true;
        },
        toggleSettings(e) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggerId = utils.computeTriggerId(e.target);
            if (this.settingsPopoverVisible) {
                this.settingDataPoints = this._getSettingDataPoints();
            }
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.settingsPopoverNavSelected = null;
            this.settingDataPointsSelected = null;
            this.searchKey = '';
            this.$emit('settings-popover-hide');
        },
        changeSettingsNav(item) {
            this.settingDataPointsSelected = null;
            this.settingsPopoverNavSelected = item;
            this.settingDataPoints = this._getSettingDataPoints();
            this.$refs.quoteUiSettings.enableTrapFocus();
        },
        removeButtonFocus(e) {
            e.target.style.opacity = 1;
        },
        removeButtonBlur(e) {
            e.target.style.opacity = 0;
        },
        flashDataPoints(value, oldValue) {
            // flash content header
            ['lastPrice', 'chg', 'chg%', 'prePostPrice', 'prePostChg%'].forEach(
                id => {
                    if (this.$refs[id] && value[id] !== oldValue[id]) {
                        const flashClass =
                            Number(value[id]) > Number(oldValue[id])
                                ? this.namespace('flash__positive')
                                : this.namespace('flash__negative');
                        if (flashClass && this.$refs[id]) {
                            utils.flash(this.$refs[id], flashClass);
                        }
                    }
                }
            );
        },
        orderDataPoints(obj) {
            if (obj.moved) {
                const securityType = getSecurityType(this.dataModel.security);
                const newDataPoints = getSelectedDataPointIds(
                    securityType,
                    this.watchedSettings[`${securityType}DataPoints`]
                );
                const [oldElement, newElement] = [
                    newDataPoints[obj.moved.oldIndex],
                    newDataPoints[obj.moved.newIndex]
                ];
                newDataPoints[obj.moved.oldIndex] = newElement;
                newDataPoints[obj.moved.newIndex] = oldElement;
                this.watchedSettings[
                    `${securityType}DataPoints`
                ] = newDataPoints;
                this.changeSettings(this._getStoreSettings());
                // Tracking: number of time rearrange data point feature is used.
                this.trackEvent({
                    name: 'data-point-rearrange'
                });
            }
        },
        removeDataPoint(item) {
            const securityType = getSecurityType(this.dataModel.security);
            const newDataPoints = getSelectedDataPointIds(
                securityType,
                this.watchedSettings[`${securityType}DataPoints`]
            ).filter(id => id !== item.id);
            this.watchedSettings[`${securityType}DataPoints`] = newDataPoints;
            this.changeSettings(this._getStoreSettings());
            if (
                this.settingDataPointsSelected &&
                this.settingDataPointsSelected[item.id]
            ) {
                this.settingDataPointsSelected[item.id] = false;
            }
        },
        changeSettingDataPoints(items) {
            this.settingDataPointsSelected = items;
        },
        changeDataPoints() {
            this.settingsPopoverVisible = false;
            if (this.settingDataPointsSelected) {
                const securityType = getSecurityType(this.dataModel.security);
                const newDataPoints = [];
                getAvailableDataPoints(securityType).forEach(d => {
                    if (this.settingDataPointsSelected[d.id]) {
                        newDataPoints.push(d.id);
                    }
                });
                if (
                    this.watchedSettings[`${securityType}DataPoints`].join(
                        ','
                    ) !== newDataPoints.join(',')
                ) {
                    this.watchedSettings[
                        `${securityType}DataPoints`
                    ] = newDataPoints;
                    this.settingDataPoints = this._getSettingDataPoints();
                    this.changeSettings(this._getStoreSettings());
                }
            }
        },
        filterDataPoints(keyword) {
            this.searchKey = keyword;
            this.settingDataPoints = this._getSettingDataPoints();
        },
        _getStoreSettings() {
            const symbol = utils.isEmptyObject(this.dataModel.security)
                ? this.watchedSettings.symbol
                : this.getSavedSymbol(this.dataModel.security);
            if (!symbol) {
                return null;
            }
            const savedSettings = {
                symbol
            };
            Object.keys(SECURITY_TYPES).forEach(securityType => {
                if (this.watchedSettings[`${securityType}DataPoints`].length) {
                    const defaultSelected = getSelectedDataPointIds(
                        securityType
                    );
                    if (
                        JSON.stringify(defaultSelected) !==
                        JSON.stringify(
                            this.watchedSettings[`${securityType}DataPoints`]
                        )
                    ) {
                        savedSettings[
                            `${securityType}DataPoints`
                        ] = this.watchedSettings[
                            `${securityType}DataPoints`
                        ].join(',');
                    }
                }
            });
            this.trackDataPointsUsage();
            return savedSettings;
        },
        trackDataPointsUsage() {
            this._getDataPoints().forEach(dataPoint => {
                // Tracking: number of data point user set to display in the component.
                this.trackEvent({
                    name: 'data-point-select',
                    value: dataPoint.name
                });
            });
        },
        showTooltip(e) {
            this.tooltips.triggeredBy = utils.computeTriggerId(e.target);
            this.tooltips.visible = true;
        },
        hideTooltip(e) {
            this.tooltips.visible = false;
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-quote';

.#{$namespace} {
    ul,
    li,
    dd,
    dl,
    dt,
    p,
    label {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    &-content-header {
        padding: 10px;
        min-height: 44px;
        display: flex;

        &__with-pre-post {
            min-height: 95px;
        }

        &-info-container {
            flex-grow: 1;
            width: calc(100% - 30px);
        }

        &-basic {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;

            &--xxl,
            &--xl,
            &--l {
                .#{$namespace}-content-header-block {
                    width: calc(100% / 3);
                }
            }

            &--m {
                .#{$namespace}-content-header__price {
                    width: auto;
                }
            }

            &--s,
            &--xs {
                .#{$namespace}-content-header-block {
                    width: 100%;
                }
            }
        }

        &__settings {
            width: 30px;
        }

        &-plus {
            padding-top: 10px;
        }

        &__quant {
            // fix dev site issue.
            @include mds-body-text-m();

            .markets-ui-star-rating {
                line-height: 0;
            }
        }

        &__security {
            // fix dev site issue.
            font-weight: $mds-typography-font-weight-regular;

            &-name {
                font-size: 20px;
                padding-right: $mds-space-1-and-a-half-x;
            }

            &-info {
                color: $mds-text-color-secondary;
                font-size: $mds-typography-font-size-s;
            }

            // fix dev site issue.
            &-symbol {
                font-size: $mds-typography-font-size-m;
            }
        }

        &__price-quant {
            display: flex;
            align-items: baseline;

            &-s,
            &-l,
            &-xl,
            &-xxl {
                .#{$namespace}-content-header__price-quant-block {
                    width: calc(100% / 2);
                }
            }

            &-m {
                .#{$namespace}-content-header__price-quant-block {
                    padding-left: 20px;
                }
            }

            &-xs {
                justify-content: space-between;
                display: inline-block;
            }
        }

        &__price {
            // fix dev site issue.
            @include mds-body-text-m();

            & &-pre-post-label {
                padding-right: 10px;
                font-weight: $mds-typography-font-weight-bold;
            }

            &-date {
                color: $mds-text-color-secondary;
                font-size: $mds-typography-font-size-s;
            }

            .#{$namespace}-neutral {
                margin-left: 5px;
            }
        }
    }

    &-content-body {
        padding: 10px;
        overflow-y: auto;

        &__datapoint-wrapper {
            display: flex;
            flex-wrap: wrap;

            &--xxl {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 12);
                }
            }

            &--xl {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 10);
                }
            }

            &--l {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 8);
                }
            }

            &--m {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 6);
                }
            }

            &--s {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 4);
                }
            }

            &--xs {
                .#{$namespace}-content-body__datapoint {
                    width: calc(100% / 2);
                }
            }
        }

        &__datapoint {
            font-size: $mds-typography-font-size-s;
            position: relative;
            padding-bottom: 20px;
            box-sizing: content-box;

            &:active {
                cursor: grabbing;
            }

            &:hover {
                .#{$namespace}-content-body__datapoint--remove {
                    opacity: 1;
                }
            }

            &--draggable span {
                &:hover {
                    cursor: grab;
                }
            }

            & dl {
                padding-right: 6px;

                & dt {
                    border-top: 1px solid #e5e5e5;
                    font-weight: $mds-typography-font-weight-bold;
                    padding: 3px 0px;
                }

                & dd {
                    @include mds-body-text-m();
                }
            }

            &--remove {
                position: absolute;
                top: 2px;
                right: 8px;
                opacity: 0;
            }

            &--remove:focus {
                opacity: 1;
            }
        }
    }

    &-down {
        color: $mds-text-color-performance-negative;
        fill: $mds-text-color-performance-negative;
        font-weight: $mds-typography-font-weight-bold;

        // fix dec site issue
        > label {
            font-weight: $mds-typography-font-weight-bold;
        }
    }

    &-neutral {
        color: $mds-text-color-performance-neutral;
        fill: $mds-text-color-performance-neutral;
        font-weight: $mds-typography-font-weight-bold;
    }

    &-up {
        color: $mds-text-color-performance-positive;
        fill: $mds-text-color-performance-positive;
        font-weight: $mds-typography-font-weight-bold;

        & > label:before {
            content: '+';
        }
    }

    &-flash {
        opacity: 0.6;

        &__positive {
            background-color: $mds-text-color-performance-positive;
            opacity: 0.8;
            color: $mds-color-white;

            span {
                color: $mds-color-white;
            }
        }

        &__negative {
            background-color: $mds-text-color-performance-negative;
            opacity: 0.8;
            color: $mds-color-white;

            span {
                color: $mds-color-white;
            }
        }
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-content {
            height: 100%;
        }
    }

    &.markets-ui-container__dark-gray {
        background: $mds-background-color-dark-gray;
        color: $mds-text-color-primary-on-dark;

        .#{$namespace}- .#{$namespace}-content-header {
            &__security {
                &-info {
                    color: $mds-text-color-secondary-on-dark;
                }
            }

            &__price {
                &-date {
                    color: $mds-text-color-secondary-on-dark;
                }
            }
        }

        .#{$namespace}-content-body {
            &__datapoint {
                & dl {
                    & dt {
                        border-top: $mds-text-color-secondary-on-dark;
                    }
                }

                &--remove {
                    svg {
                        fill: $mds-text-color-primary-on-dark;
                    }
                }
            }
        }

        .#{$namespace}-down {
            color: $mds-text-color-performance-negative-on-dark;
            fill: $mds-text-color-performance-negative-on-dark;
        }

        .#{$namespace}-neutral {
            color: $mds-text-color-performance-neutral-on-dark;
            fill: $mds-text-color-performance-neutral-on-dark;
        }

        .#{$namespace}-up {
            color: $mds-text-color-performance-positive-on-dark;
            fill: $mds-text-color-performance-positive-on-dark;
        }

        .#{$namespace}-flash {
            &__positive {
                background-color: $mds-text-color-performance-positive-on-dark;
            }

            &__negative {
                background-color: $mds-text-color-performance-negative-on-dark;
            }
        }
    }
}
</style>

export const SECURITY_TYPES = {
    ST: 'ST',
    FO: 'FO',
    FE: 'FE',
    FC: 'FC',
    XI: 'XI',
    FM: 'FO',
    OP: 'OP'
};
export function getSecurityType(security) {
    const securityType = security.mstarType || security.rtType;
    if (securityType === '2') {
        return SECURITY_TYPES.OP;
    }
    return (securityType && SECURITY_TYPES[securityType]) || SECURITY_TYPES.ST;
}

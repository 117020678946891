(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("vuedraggable"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "vuedraggable"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsQuote"] = factory(require("mwc-markets-core"), require("vuedraggable"));
	else
		root["mwcMarketsQuote"] = factory(root["mwcMarketsCore"], root["vuedraggable"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__82__) {
return 
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.namespace("content"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm.watchedSettings.showContentHeader
                ? _c(
                    "div",
                    {
                      ref: "contentHeader",
                      class: [
                        _vm.namespace("content-header"),
                        _vm.headerData.prePostPriceData
                          ? _vm.namespace("content-header__with-pre-post")
                          : ""
                      ]
                    },
                    [
                      _c(
                        "div",
                        {
                          class: _vm.namespace("content-header-info-container")
                        },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                _vm.namespace("content-header-basic"),
                                _vm.namespace(
                                  "content-header-basic--" + _vm.breakPoint
                                )
                              ]
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: [
                                    _vm.namespace("content-header-block"),
                                    _vm.namespace("content-header__security")
                                  ]
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        class: _vm.namespace(
                                          "content-header__security-name"
                                        )
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.headerData.security.name)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        class: _vm.namespace(
                                          "content-header__security-symbol"
                                        )
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.headerData.security.symbol)
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: _vm.namespace(
                                        "content-header__security-info"
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.headerData.security.currency
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "| " +
                                            _vm._s(
                                              _vm.headerData.security.exchange
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.isLayoutChanged
                                ? _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.namespace("content-header-block"),
                                        _vm.namespace(
                                          "content-header__price-quant"
                                        ),
                                        _vm.namespace(
                                          "content-header__price-quant-" +
                                            _vm.breakPoint
                                        )
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.namespace(
                                              "content-header__price-quant-block"
                                            ),
                                            _vm.namespace(
                                              "content-header__price"
                                            )
                                          ]
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("span", { ref: "lastPrice" }, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.headerData.regularPice
                                                      .price
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _vm.headerData.regularPice
                                                .updown !== "neutral"
                                                ? _c("markets-ui-icon", {
                                                    attrs: {
                                                      skin: _vm.skin,
                                                      performance:
                                                        _vm.headerData
                                                          .regularPice.updown,
                                                      name:
                                                        "ip-performance-arrow-" +
                                                        _vm.headerData
                                                          .regularPice.updown
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  class: _vm.namespace(
                                                    _vm.headerData.regularPice
                                                      .updown
                                                  )
                                                },
                                                [
                                                  _c("label", { ref: "chg" }, [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headerData
                                                          .regularPice.chg
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    "\n                                    |\n                                    "
                                                  ),
                                                  _c("label", { ref: "chg%" }, [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.headerData
                                                            .regularPice.chgp
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                    _c("sup", [_vm._v("%")])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            [
                                              _vm.headerData.regularPice.volume
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.initedLabels
                                                            .volumeAbbriation
                                                        ) +
                                                        "\n                                    " +
                                                        _vm._s(
                                                          _vm.headerData
                                                            .regularPice.volume
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  class: _vm.namespace(
                                                    "content-header__price-date"
                                                  )
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.headerData
                                                          .regularPice.dateTime
                                                      ) +
                                                      "\n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  class: _vm.namespace(
                                                    "content-header__price-date"
                                                  ),
                                                  on: {
                                                    mouseenter: _vm.showTooltip,
                                                    mouseleave: _vm.hideTooltip
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        _vm.headerData
                                                          .regularPice
                                                          .shorTimeZone
                                                      ) +
                                                      "\n                                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.watchedSettings
                                                .showTimeZoneToolTip &&
                                              _vm.dataModel.security.timezone &&
                                              _vm.dataModel.security
                                                .timezoneAbbreviation
                                                ? _c("markets-ui-tooltip", {
                                                    class: _vm.namespace(
                                                      "tooltips"
                                                    ),
                                                    attrs: {
                                                      visible:
                                                        _vm.tooltips.visible,
                                                      "triggered-by":
                                                        _vm.tooltips
                                                          .triggeredBy,
                                                      text:
                                                        _vm.headerData
                                                          .regularPice
                                                          .timezoneAbb
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            _vm.namespace(
                                              "content-header__price-quant-block"
                                            ),
                                            _vm.namespace(
                                              "content-header__quant"
                                            )
                                          ]
                                        },
                                        [
                                          _vm.headerData.quant.rating
                                            ? _c(
                                                "div",
                                                [
                                                  _c("markets-ui-star-rating", {
                                                    attrs: {
                                                      skin:
                                                        _vm.watchedSettings
                                                          .skin,
                                                      value:
                                                        _vm.headerData.quant
                                                          .rating
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.headerData.quant.valuation
                                            ? _c("div", [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.headerData.quant
                                                        .valuation
                                                    ) +
                                                    "\n                            "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isLayoutChanged
                                ? _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.namespace("content-header-block"),
                                        _vm.namespace("content-header__price")
                                      ]
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("span", { ref: "lastPrice" }, [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.headerData.regularPice
                                                    .price
                                                ) +
                                                "\n                            "
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _vm.headerData.regularPice.updown !==
                                          "neutral"
                                            ? _c("markets-ui-icon", {
                                                attrs: {
                                                  performance:
                                                    _vm.headerData.regularPice
                                                      .updown,
                                                  skin: _vm.skin,
                                                  name:
                                                    "ip-performance-arrow-" +
                                                    _vm.headerData.regularPice
                                                      .updown
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.namespace(
                                                _vm.headerData.regularPice
                                                  .updown
                                              )
                                            },
                                            [
                                              _c("label", { ref: "chg" }, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.headerData.regularPice
                                                      .chg
                                                  )
                                                )
                                              ]),
                                              _vm._v(
                                                "\n                                |\n                                "
                                              ),
                                              _c("label", { ref: "chg%" }, [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.headerData.regularPice
                                                        .chgp
                                                    ) +
                                                    "\n                                    "
                                                ),
                                                _c("sup", [_vm._v("%")])
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _vm.headerData.regularPice.volume
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(
                                                      _vm.initedLabels
                                                        .volumeAbbriation
                                                    ) +
                                                    "\n                                " +
                                                    _vm._s(
                                                      _vm.headerData.regularPice
                                                        .volume
                                                    ) +
                                                    "\n                            "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.namespace(
                                                "content-header__price-date"
                                              )
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.headerData.regularPice
                                                    .dateTime
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              class: _vm.namespace(
                                                "content-header__price-date"
                                              ),
                                              on: {
                                                mouseenter: _vm.showTooltip,
                                                mouseleave: _vm.hideTooltip
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.headerData.regularPice
                                                      .shorTimeZone
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.watchedSettings
                                            .showTimeZoneToolTip &&
                                          _vm.dataModel.security.timezone &&
                                          _vm.dataModel.security
                                            .timezoneAbbreviation
                                            ? _c("markets-ui-tooltip", {
                                                class: _vm.namespace(
                                                  "tooltips"
                                                ),
                                                attrs: {
                                                  visible: _vm.tooltips.visible,
                                                  "triggered-by":
                                                    _vm.tooltips.triggeredBy,
                                                  text:
                                                    _vm.headerData.regularPice
                                                      .timezoneAbb
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isLayoutChanged
                                ? _c(
                                    "div",
                                    {
                                      class: [
                                        _vm.namespace("content-header__block"),
                                        _vm.namespace("content-header__quant")
                                      ]
                                    },
                                    [
                                      _vm.headerData.quant.rating
                                        ? _c(
                                            "div",
                                            [
                                              _c("markets-ui-star-rating", {
                                                attrs: {
                                                  skin:
                                                    _vm.watchedSettings.skin,
                                                  value:
                                                    _vm.headerData.quant.rating
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.headerData.quant.valuation
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.headerData.quant.valuation
                                                ) +
                                                "\n                        "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _vm.headerData.prePostPriceData && _vm.showPrePost
                            ? _c(
                                "div",
                                { class: _vm.namespace("content-header-plus") },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: _vm.namespace(
                                        "content-header__price"
                                      )
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "label",
                                          {
                                            class: _vm.namespace(
                                              "content-header__price-pre-post-label"
                                            )
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.headerData
                                                    .prePostPriceData.label
                                                ) +
                                                "\n                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { ref: "prePostPrice" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.headerData.prePostPriceData
                                                  .price
                                              ) +
                                              "\n                                |\n                            "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            class: _vm.namespace(
                                              _vm.headerData.prePostPriceData
                                                .updown
                                            )
                                          },
                                          [
                                            _c(
                                              "label",
                                              { ref: "prePostChg%" },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.headerData
                                                        .prePostPriceData.chgp
                                                    ) +
                                                    "\n                                    "
                                                ),
                                                _c("sup", [_vm._v("%")])
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.namespace(
                                            "content-header__price-date"
                                          )
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.headerData.prePostPriceData
                                                  .dateTime
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          class: _vm.namespace(
                                            "content-header__price-date"
                                          ),
                                          on: {
                                            mouseenter: _vm.showTooltip,
                                            mouseleave: _vm.hideTooltip
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.headerData.regularPice
                                                  .shorTimeZone
                                              ) +
                                              "\n                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.watchedSettings.showTimeZoneToolTip &&
                                      _vm.dataModel.security.timezone &&
                                      _vm.dataModel.security
                                        .timezoneAbbreviation
                                        ? _c("markets-ui-tooltip", {
                                            class: _vm.namespace("tooltips"),
                                            attrs: {
                                              visible: _vm.tooltips.visible,
                                              "triggered-by":
                                                _vm.tooltips.triggeredBy,
                                              text:
                                                _vm.headerData.regularPice
                                                  .timezoneAbb
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showWebLayout
                        ? _c(
                            "div",
                            {
                              class: _vm.namespace("content-header__settings")
                            },
                            [
                              _c("markets-ui-button", {
                                ref: "settingsButton",
                                attrs: {
                                  skin: _vm.skin,
                                  icon: "gear",
                                  size: "medium",
                                  text: _vm.initedLabels.settings,
                                  variation: "icon-only",
                                  "aria-expanded":
                                    "" + _vm.settingsPopoverVisible
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleSettings($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: [
                    _vm.namespace("content-body"),
                    _vm.watchedSettings.drag
                      ? _vm.namespace("content-body__datapoint--draggable")
                      : ""
                  ],
                  style: _vm.watchedSettings.autoHeight
                    ? ""
                    : "height:calc(100% - " +
                      (_vm.contentHeaderHeight + _vm.contentBodyPadding) +
                      "px);"
                },
                [
                  _c(
                    "draggable",
                    {
                      class: [
                        _vm.namespace("content-body__datapoint-wrapper"),
                        _vm.namespace(
                          "content-body__datapoint-wrapper--" + _vm.breakPoint
                        )
                      ],
                      attrs: {
                        draggable:
                          "." +
                          _vm.namespace(
                            "content-body__datapoint--draggable-item"
                          ),
                        list: _vm.dragDataPoints,
                        sort: _vm.watchedSettings.drag
                      },
                      on: { change: _vm.orderDataPoints }
                    },
                    _vm._l(_vm.dragDataPoints, function(item) {
                      return _c(
                        "span",
                        {
                          key: item.id,
                          class: [
                            _vm.namespace("content-body__datapoint"),
                            _vm.watchedSettings.drag
                              ? _vm.namespace(
                                  "content-body__datapoint--draggable-item"
                                )
                              : ""
                          ]
                        },
                        [
                          _c("dl", [
                            _c("dt", [_vm._v(_vm._s(item.name))]),
                            _vm._v(" "),
                            item.id === "MSStyleBox"
                              ? _c(
                                  "dd",
                                  [
                                    _c("markets-ui-style-box", {
                                      attrs: {
                                        skin: _vm.watchedSettings.skin,
                                        value: item.value
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "dd",
                                  {
                                    attrs: { "x-ms-format-detection": "none" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.value) +
                                        "\n                        "
                                    )
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _vm.watchedSettings.removable
                            ? _c("markets-ui-button", {
                                class: _vm.namespace(
                                  "content-body__datapoint--remove"
                                ),
                                attrs: {
                                  "aria-hidden": "true",
                                  tabindex: "-1",
                                  skin: _vm.skin,
                                  variation: "icon-only",
                                  size: "small",
                                  icon: "remove",
                                  text: _vm.initedLabels.remove
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeDataPoint(item)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "quoteUiSettings",
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            showBack: !!_vm.showDataPointsSetting && !_vm.showWebLayout,
            showDone: !!_vm.showDataPointsSetting,
            showSearchField: _vm.showDataPointsSetting,
            "triggered-by": _vm.settingsPopoverTriggerId,
            title: _vm.settingsPopoverTitle,
            doneLabel: _vm.initedLabels.labelDone,
            visible: _vm.settingsPopoverVisible,
            searchFieldPlaceholder: _vm.initedLabels.search
          },
          on: {
            hide: _vm.hideSettingsPopover,
            back: _vm.changeSettingsNav,
            done: _vm.changeDataPoints,
            "search-key-changed": _vm.filterDataPoints
          },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected && !_vm.showWebLayout
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "markets-ui-form",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.showDataPointsSetting
                ? _c("markets-ui-checkbox-group", {
                    attrs: {
                      size: "small",
                      skin: _vm.watchedSettings.skin,
                      dataModel: _vm.settingDataPoints
                    },
                    on: { change: _vm.changeSettingDataPoints }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
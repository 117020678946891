<template>
    <QuoteUI
        ref="UI"
        :skin="skin"
        :dataModel="dataModel"
        :showLoading="showLoading"
        :showPrePost="showPrePost"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @track="trackEvent"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
    ></QuoteUI>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import QuoteUI from './quote-ui';
import { getDataModelPointIds } from './metadata/data-point';
import { getSecurityType } from './metadata/sec-types';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-quote',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: {
        symbol: {
            dataType: String
        }
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: true,
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    showPrePost: false,
                    showContentHeader: true,
                    updateLastAccessTime: true,
                    removable: false,
                    STDataPoints: '',
                    FODataPoints: '',
                    FEDataPoints: '',
                    FCDataPoints: '',
                    XIDataPoints: '',
                    skin: '',
                    dataType: '',
                    drag: true,
                    showTimeZoneToolTip: false
                },
                labels: labels,
                format: {
                    integer: {
                        dataType: 'number',
                        useGrouping: true
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-quote'
            },
            dataModel: {
                security: {},
                data: {}
            },
            showLoading: false,
            showPrePost: true
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    watch: {
        symbol: function(value) {
            if (value && typeof value === 'string') {
                this.changeSymbol(value);
                // Tracking: change symbol.
                this.trackEvent({
                    name: 'symbol-change',
                    value
                });
            }
        }
    },
    created() {
        this.watchedSymbol = null;
        this.currentDataType = null;
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.initialized = false;
        this.targetVisibility = false;
        this.changeDataType(this.settings.dataType);
        this.changeSymbol(this.settings.symbol);
        // Tracking: change symbol.
        this.trackEvent({
            name: 'symbol-change',
            value: this.settings.symbol
        });
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        this._unsubscribe(this.currentDataType, this.watchedSymbol);
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.watchedSymbol) {
                this.changeSymbol(this.watchedSymbol).then(() => {
                    if (!this.error.code) {
                        this.renderCompleted = true;
                    }
                });
            }
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._subscribe(this.currentDataType);
                }
            } else {
                if (!this.targetVisibility) {
                    this._unsubscribe(this.currentDataType, this.watchedSymbol);
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            this._unsubscribe(oldValue, this.watchedSymbol);
            if (value === 'stream') {
                this.currentDataType = value;
                this.showPrePost = false;
            } else {
                this.showPrePost = true;
                this.currentDataType = 'pull';
            }
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            this._subscribe(this.currentDataType);
        },
        changeSymbol(symbol) {
            if (!this.mktdata) {
                return;
            }
            // first need unsubscribe the old symbol.
            this._unsubscribe(this.currentDataType, this.watchedSymbol);
            this.watchedSymbol = symbol;
            if (this.initialized) {
                this.showLoading = true;
                this.acceptTicker(this.watchedSymbol);
                return this._getSnapshot().then(() => {
                    this._subscribe(this.currentDataType);
                });
            }
        },
        updateData(security, updates) {
            if (
                this.dataModel.security &&
                this.dataModel.security.queryKey === security
            ) {
                this._fillDataModel(updates);
            }
        },
        _getSnapshot() {
            if (this.watchedSymbol) {
                this.showLoading = true;
                this.error = {};
                this.dataModel = {
                    security: {},
                    data: {}
                };
                return this.mktdata
                    .securities([this.watchedSymbol])
                    .then(tickers => {
                        let secDetail = tickers.map(item => {
                            const tickerCopy = utils.extend(true, {}, item);
                            tickerCopy.isPermit = item.isPermit;
                            tickerCopy.isValid = item.isValid;
                            return tickerCopy;
                        });
                        const promiseList = [
                            this.mktdata
                                .quotes({
                                    securities: [this.watchedSymbol]
                                })
                                .catch(e => {
                                    this._throwError(e, 'quotes');
                                }),
                            this.mktdata
                                .staticData({
                                    securities: [this.watchedSymbol]
                                })
                                .catch(e => {
                                    this._throwError(e, 'statics');
                                })
                        ];
                        Promise.all(promiseList)
                            .then(res => {
                                const [quoteData, staticData] = res;
                                if (secDetail[0] && (quoteData || staticData)) {
                                    this.dataModel.security = secDetail[0];
                                    if (secDetail[0].strikePrice) {
                                        this.dataModel.data.strikePrice =
                                            secDetail[0].strikePrice;
                                    }
                                    this._fillDataModel(
                                        staticData[0].statics.concat(
                                            quoteData[0].quotes
                                        )
                                    );
                                    this.showLoading = false;
                                } else {
                                    this._checkExchangePermission(secDetail);
                                }
                            })
                            .catch(e => {
                                this._checkExchangePermission(secDetail);
                            });
                    })
                    .catch(() => {
                        this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
                        this.showLoading = false;
                    });
            } else {
                this._setError(this.ERROR_TYPES.EMPTY_INVESTMENT);
                this.showLoading = false;
            }
        },
        _checkExchangePermission(secDetail) {
            if (!secDetail[0].isPermit()) {
                this._setError(this.ERROR_TYPES.No_PERMISSION_INVESTMENT);
            } else {
                this._setError(this.ERROR_TYPES.INVALID_INVESTMENT);
            }
            this.showLoading = false;
        },
        _subscribe(dataType) {
            if (dataType && this.watchedSymbol) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(
                            [this.watchedSymbol],
                            this.subscribeListener,
                            {
                                updateLastAccessTime: this.settings
                                    .updateLastAccessTime
                            }
                        );
                    })
                    .catch(e => {
                        this.showLoading = false;
                    });
            }
        },
        _unsubscribe(dataType, symbol) {
            if (!this.isSubscribing) {
                return;
            }
            this.isSubscribing = false;
            if (dataType && symbol) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(
                            [symbol],
                            this.subscribeListener
                        );
                    })
                    .catch(e => {});
            }
        },
        _fillDataModel(array = []) {
            if (array.length) {
                const securityType = getSecurityType(this.dataModel.security);
                const dataPoints = getDataModelPointIds(securityType);
                const data = {};
                array.forEach(d => {
                    if (utils.inArray(dataPoints, d.name) > -1) {
                        data[d.name] = d.value;
                    }
                });
                this.dataModel.data = utils.extend(
                    true,
                    {},
                    this.dataModel.data,
                    data
                );
                this.dataModel.data.tradeExchange =
                    this.dataModel.data.extraExchangeInfo ||
                    this.dataModel.data.tradeExchange;
            }
        },
        _throwError(e, type) {
            if (e.code === -2) {
                throw e;
            }
            return Promise.resolve([
                {
                    security: {},
                    [type]: []
                }
            ]);
        },
        _setError(err) {
            this.error = err;
            this.dataModel = {
                security: {},
                data: {}
            };
            this.showLoading = false;
        }
    },
    components: {
        QuoteUI
    }
};
</script>
